import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { auth, backEndUrl, backApiKey } from "../../config";
import GameLibrary from "../../components/gameLibrary/GameLibrary";
import GameDetails from "../../components/gameDetails/GameDetails";
import { motion } from "framer-motion";

const LibraryPage = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserGames = async (uid) => {
      try {
        const response = await fetch(`${backEndUrl}/users/${uid}`, {
          headers: {
            "x-api-key": backApiKey,
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          const gameIds = data.userInfo.gameIds;

          const gamesDataPromises = gameIds.map(async (gameId) => {
            const gameResponse = await fetch(`${backEndUrl}/games/${gameId}`, {
              headers: {
                "x-api-key": backApiKey,
              },
            });

            if (gameResponse.status === 200) {
              return await gameResponse.json();
            } else {
              console.error(
                "Error fetching game details: ",
                gameResponse.statusText
              );
              return null;
            }
          });

          const gamesData = await Promise.all(gamesDataPromises);

          setGames(gamesData.filter((gameData) => gameData !== null));
          setLoading(false);
        } else {
          console.error("Error fetching user games: ", response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user games:", error);
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserGames(user.uid);
      } else {
        console.error("User not authenticated");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGameClick = (game) => {
    setSelectedGame(game);
  };

  const handleRemoveGame = (gameId) => {
    setGames(games.filter((game) => game.id !== gameId));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-slate-900 to-black text-white p-36">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row gap-8">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="w-full lg:w-1/3"
          >
            <GameLibrary
              games={games}
              onGameClick={handleGameClick}
              loading={loading}
              onRemoveGame={handleRemoveGame}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="w-full lg:w-2/3"
          >
            <GameDetails game={selectedGame} />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default LibraryPage;